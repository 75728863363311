@import "variables.scss";
@import "fonts.scss";
@import "settings.scss";
@import "bootstrap/scss/bootstrap.scss";

body {
  word-break: break-word;
}

h1 {
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  font-size: 2.25rem;
  line-height: 2.688rem;
}

h2 {
  font-size: 1.5rem;
  line-height: 2.063rem;
  margin: 0;
}

h3 {
  font-size: 1.25rem;
  line-height: 1.438rem;
}

a {
  color: $colorLink;
  &:hover {
    color: darken($colorLink, 0.5);
  }
}

.email-group-name {
  cursor: pointer;
  user-select: none;
}

p.large,
span.large {
  font-size: 1.125rem;
  line-height: 1.563rem;
}

.caption {
  font-size: 0.75rem;
  line-height: 0.833rem;
  color: $colorCaption;
  border-top: 1px solid rgba(#000, 0.05);
}

.page-center {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  height: 60px;
  width: auto;
}

.form-footer {
  button.btn {
    position: relative;
    z-index: 1;
    border-radius: $merchantBorderRadius;
    padding: 0.75rem 2rem;
    background: $merchantButtonColor;
    border-color: $merchantButtonColor;
    color: $merchantButtonFontColor;

    .spinner {
      display: none;

      &.show {
        display: inline-block;
      }
    }
  }

  .store-link {
    text-decoration: underline;
  }
}
